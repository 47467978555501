.App {
  text-align: center;
  overflow-x: hidden;
  direction: rtl;
}

.error {
  color: red;
  font-weight: bold;
}

.button {
  background-color: white;
  font-weight: bold;
  border: 3px solid black;
  min-width: 140px;
  padding: 8px 20px;
  font-size: 22px;
  border-radius: 8px;
  cursor: pointer;
}

.button-small {
  background-color: white;
  font-weight: bold;
  border: 3px solid black;
  min-width: 80px;
  padding: 4px 10px;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
}
