.headerInfo {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: #ffffff;
  margin-right: 20px;
  margin-left: 20px;
}
.headerInfo__buttons {
  width: 25vw;
  display: flex;
  justify-content: space-evenly;
}

.headerInfo__buttons div {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.authButton {
  border-radius: 8px;
  font-size: 22px;
  padding: 4px 10px;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: -5px 4px #888888;
  transition: all 0.4s;
  background-color: #cd9042;
  color: white;
  margin-right: auto;
  margin-left: 20px;
}

.authButton:hover {
  box-shadow: 0px 0px #888888;
  transform: scale(0.9);
}
