body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  border-top: 1.1em solid #006d9e;
  border-right: 1.1em solid #006d9e;
  border-bottom: 1.1em solid #006d9e;
  border-left: 1.1em solid #064059;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
}

.spinner::after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type='date'] {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* .react-calendar__month-view__days {
  width: 80%;
}
*/

.react-calendar__month-view__weekdays {
  margin-bottom: 20px;
}

.react-calendar__month-view {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.day-tile {
  height: 60px;
  background-color: white;
  border: 1px solid black;
  font-size: 20px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
/* .day-tile where not disabled */
.day-tile:disabled {
  cursor: not-allowed;
  color: black;
  text-decoration: none;
}

.day-tile:hover {
  background-color: #f0f0f0;
}

.day-tile:hover:disabled {
  background-color: white;
}

.calendar-tileContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-tileContent span {
  margin-top: -5px;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.15);
}

.no-drag {
  /* Add any styles to indicate that the element is not draggable */
  cursor: move;
}

.dragging {
  /* Add any styles to visually indicate the dragging effect */
  opacity: 0.5;
  cursor: move;
  /* Additional styles for the element's position */
  /* position: fixed; */
  z-index: 9999;
}
