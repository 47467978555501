.admin-app {
  direction: ltr;
}

/* General styles */

.label-input-combo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 20px;
  font-weight: bold;
}

.label-input-combo input {
  font-size: 22px;
  width: 260px;
  border: 3px solid black;
  padding: 4px 12px;
  border-radius: 8px;
  margin-top: 5px;
  outline: none;
}
