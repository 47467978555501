.ant-message {
  direction: ltr;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.ant-picker-calendar th {
  display: none;
}

.ql-editor {
  direction: rtl !important;
  text-align: right !important;
}
